<template>
  <v-row>
    <v-col>
      <v-autocomplete
        v-model="state.first"
        :items="options.firstOptions"
        hide-details
        auto-select-first
        background-color="white"
        dense
        outlined
      />
    </v-col>
    <v-col>
      <v-autocomplete
        v-model="state.second"
        :items="options.secondOptions"
        hide-details
        auto-select-first
        background-color="white"
        dense
        outlined
      />
    </v-col>
    <v-col>
      <v-autocomplete
        v-model="state.third"
        :items="options.thirdOptions"
        hide-details
        auto-select-first
        background-color="white"
        dense
        outlined
      />
    </v-col>
    <v-col>
      <v-autocomplete
        v-model="state.fourth"
        :items="options.fourthOptions"
        hide-details
        auto-select-first
        background-color="white"
        dense
        outlined
      />
    </v-col>
    <v-col>
      <v-btn
        text
        min-width="40px"
        width="40px"
        :disabled="rules.length === 1"
        @click="$emit('remove',index)"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-btn
        v-if="rules.length - 1 === index"
        min-width="40px"
        width="40px"
        text
        @click="$emit('add')"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props:{
    value:{
      required:true,
      type:Object,
    },
    index:{
      type:Number,
      required:true
    },
    rules:{
      type:Array,
      required:true
    },
    options:{
      type:Object,
      required:true
    }
  },
  data(){
    return{
      state:{
        first:null,
        second:null,
        third:null,
        fourth:null
      }
    }
  },
  watch:{
    state:{
      handler(rule){
        this.$emit('update', {value:rule, index:this.index})
      },
      deep:true
    },
    value:{
      handler(rule){
        this.state = rule
      },
      immediate:true,
      deep:true
    }
  }
}
</script>