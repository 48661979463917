<template>
  <div class="extra-tnved-rules">
    <rule
      v-for="(rule, index) in rules"
      :key="index"
      :value="rule"
      :index="index"
      :rules="rules"
      :options="options"
      @add="add"
      @remove="remove"
      @update="update"
    />
  </div>
</template>
<script>
import Rule from '@/components/documents/catalogs/extra-tnved-rule.vue'
export default {
  components:{
    Rule
  },
  props:{
    value:{
      type:Array,
      required:true
    },
    options:{
      type:Object,
      required:true
    }
  },
  data(){
    return{
      rules:[]
    }
  },
  watch:{
    rules:{
      handler(){
        this.$emit('input', this.rules)
      },
      deep:true,
      immediate:true
    }
  },
  created() {
    this.setDefaultRules()
  },
  methods:{
    setDefaultRules(){
      if(this.value.length){
        return this.rules = this.value
      }
      return this.add()
    },
    add(){
      const rule = {
        first:null,
        second:null,
        third:null,
        fourth:null
      }
      this.rules.push(rule)
    },
    remove(index){
      this.rules.splice(index, 1)
    },
    update({value, index}){
      this.rules.splice(index, 1, value)
    }
  },

}
</script>
<style scoped>
.extra-tnved-rules{
  height: 300px;
  overflow-y: scroll;
}
</style>
